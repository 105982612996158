import React from 'react';
import { useSiteMetadata } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import FeatureDisplay, {
	FeatureDisplayImage,
	FeatureText,
	useFeatureDisplayImageClassName
} from '../../components/FeatureDisplay'
import FeatureDetailPage from '../../components/FeatureDetailPage';
import PageInfo from '../../components/PageInfo'
import FeatureNav from '../../components/FeatureNav'
import { graphql } from 'gatsby'
import Brand from '../../components/shared/Brand'
import RouterLink from '../../components/shared/RouterLink'
import ImgWithFloatingClippedImg from '../../components/shared/display/ImgWithFloatingClippedImg'

export default function Invoicing(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();
	const featureImgClassName = useFeatureDisplayImageClassName();
	return (
		<AppLayout>
			<FeatureNav/>
			<FeatureDetailPage
				title="Send Invoices to Clients for your Time and Expenses"
				description={(<>
					<p>
						If you need to keep track of time and expenses for clients, there's a good chance you plan to send them invoices later.
						It's seamless to use the invoicing capabilities of <Brand/> with your time and expenses.
						You'll be sending invoices with little effort so that you can be paid for your work.
					</p>
					<p>
						<Brand/> keeps track of projects on a per-client basis.  And you can set up automatically created invoices.
						As you and your team members are logging <RouterLink to="/features/">time</RouterLink> and
						{' '}<RouterLink to="/features/expenses/">expenses</RouterLink> against projects, you won't
						have any work to do when each scheduled invoice is ready. All you have to do is send them to your clients,
						and <Brand/> can do that too.
					</p>
				</>)}
			>
				<PageInfo
					title={"Send Invoices to Clients for your Time and Expenses with " + productName}
					description={"Send invoices with " + productName + " time and expense tracker. Easily add invoice line items and send professional invoices so that you can be paid for your work."}
				/>

				<section className="tt-limit-content-width">

					<FeatureDisplay
						header="Work on Projects and Send Invoices to Clients"
						imgPlacement="top"
						img={(
							<ImgWithFloatingClippedImg
								primaryImageData={data.invoicingImg.childImageSharp.gatsbyImageData}
								primaryImageAlt="Viewing an Invoice"
								primaryImageMaxWidth={750}
								className={featureImgClassName}
								floatingImageData={data.sampleInvoice.childImageSharp.gatsbyImageData}
								floatingImageAlt="Sample Invoice"
								transformStyle="scale(0.92, 0.92) translate(33%, 35%) rotate(30deg)"
							/>
						)}
					>
						<FeatureText
							description={(<>
								<p><Brand/> invoicing features make getting paid easier!  Create invoices for your clients in no time.</p>
								<p>
									By assigning projects to your clients, invoice line items can easily be created based on your billable time and expenses.
									For each client, you can choose invoicing settings such as hourly rates, days until due, and hours rounding mode.
								</p>
								<p>
									<Brand/> can create invoices whether you are charging based on time or using flat rates.
									Plus you can add custom line items to your invoices.
								</p>
								<p>
									You can even include your time log and expense details in a
									{' '}<RouterLink to="/blog/invoice-spreadsheet-attachments/">spreadsheet attachment with customized columns</RouterLink>.
									Spreadsheets allow you to provide all the information to your clients in an alternative format.
									And by doing so, you don't necessarily have to include as many line items cluttering the invoice.
									For a simpler invoice with fewer line items per project, you can group multiple time entries into one line item.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Log Time and Expenses to Your Projects"
						odd
						imgPlacement="left"
						imgGridLg={7}
						img={<FeatureDisplayImage imgData={data.clientProjectsImg.childImageSharp.gatsbyImageData} alt="Projects for a Client"/>}
					>
						<FeatureText
							description={(<>
								{/*this section is here to make it clear you don't have to automate your invoices (the
								next section). I.e. you can log time/expenses and create a manual invoice*/}
								<p>As you log time and expenses, you'll be filing them under projects.</p>
								<p>
									When it's time to make an invoice for a client, <Brand/> will look at all the client's projects.
									And those billable time logs and expenses will be converted into invoice line items.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Automate Invoices for your Time and Expenses"
						imgPlacement="right"
						imgGridLg={6}
						img={<FeatureDisplayImage imgData={data.automaticInvoicesImg.childImageSharp.gatsbyImageData} alt="Automatic Invoices"/>}
					>
						<FeatureText
							description={(<>
								<p>
									If you prefer, you can set up automatic invoices for recurring periods.  Then <Brand/> will create the invoices for you!
									You always have the option to create invoices manually too.
								</p>
								<p>
									If you choose, we'll notify you when each invoice is ready to be finalized and sent to your client.
									You can approve or edit the invoice before sending it.
								</p>
								<p>
									Also, you can configure your invoices for each client to be created weekly, bi-weekly, semi-monthly, or monthly.
									If you set an "Invoice Creation Delay", your team will have some time to finish tasks after each invoicing period ends.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Adjust Your Invoice Before Sending it to Your Client"
						odd
						imgPlacement="left"
						imgGridLg={6}
						img={<FeatureDisplayImage imgData={data.adjustInvoiceDataImg.childImageSharp.gatsbyImageData} alt="Adjusting Invoice Data"/>}
					>
						<FeatureText
							description={(<>
								<p>
									Before you finalize a draft invoice, you may make some edits such as the memo, days until due, etc.
									For the line item values, you may want to adjust the amounts, descriptions, and dates.
								</p>
								<p>That's a good time to preview the invoice PDF file as well, which will be attached to the email.</p>
								<p>Now that you're all ready, finalize the invoice and optionally email it to your client.</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Customize the Formats and Contact Info on Your Invoices"
						imgPlacement="right"
						img={<FeatureDisplayImage imgData={data.settingsImg.childImageSharp.gatsbyImageData} alt="Edit Invoicing Settings"/>}
					>
						<FeatureText
							description={(<>
								<p>Every team has different requirements, so <Brand/> allows you to configure invoices with your preferred date/time and number formats.</p>
								<p>
									Additionally, you can customize the currency and time zone.
									Dates will be determined based on the team's time zone.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Export Your Invoices to QuickBooks Online or Xero"
						odd
						imgPlacement="left"
						imgGridLg={6}
						img={<FeatureDisplayImage imgData={data.exportToQuickBooksOrXeroImg.childImageSharp.gatsbyImageData} alt="Exporting Invoices to QuickBooks Online or Xero"/>}
					>
						<FeatureText
							description={(<>
								<p>
									If you need to update your accounting software, <Brand/> can
									{' '}<RouterLink to="/blog/export-invoices-for-quickbooks-or-xero/">export your invoices</RouterLink>
									{' '}into formats expected by QuickBooks® and Xero™.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

				</section>

			</FeatureDetailPage>
		</AppLayout>
	);
}
export const query = graphql`{
  adjustInvoiceDataImg: file(relativePath: {eq: "images/features/invoicing/adjust-invoice-data.png"}) {
    childImageSharp { gatsbyImageData(width: 482, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  automaticInvoicesImg: file(relativePath: {eq: "images/features/invoicing/automatic-invoices.png"}) {
    childImageSharp { gatsbyImageData(width: 530, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  clientProjectsImg: file(relativePath: {eq: "images/features/invoicing/client-projects.png"}) {
    childImageSharp { gatsbyImageData(width: 530, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  exportToQuickBooksOrXeroImg: file(relativePath: {eq: "images/features/invoicing/export-to-quickbooks-or-xero.png"}) {
    childImageSharp { gatsbyImageData(width: 316, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  invoicingImg: file(relativePath: {eq: "images/features/invoicing/invoicing.png"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  sampleInvoice: file(relativePath: {eq: "images/sample-invoice.png"}) {
    childImageSharp { gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  settingsImg: file(relativePath: {eq: "images/features/invoicing/settings.png"}) {
    childImageSharp { gatsbyImageData(width: 597, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
