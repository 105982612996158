import React from 'react'
import * as PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage } from 'gatsby-plugin-image'

const useImgWithFloatingClippedImgStyles = makeStyles(theme => ({
	imgContainer: {
		position: "relative",
		margin: [[0, "auto"]],
		maxWidth: props => props.maxWidth,
	},
	floatingImgWrapper: {
		display: "flex",
		justifyContent: "center",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	floatingImgSpacePermitted: {
		flex: "1 1 500px",
		maxWidth: "500px",
	},
	spaceInhibitor: {
		flex: "1 1 250px",
		maxWidth: "250px",
	},
	floatingImg: {
		boxShadow: theme.shadows[8],
		border: "1px solid #707070",
		transform: props => props.transformStyle,
		opacity: "95%",
	},
}), {name: "ImgWithFloatingClippedImg"});

export default function ImgWithFloatingClippedImg(props) {
	let {primaryImageData, primaryImageAlt, primaryImageMaxWidth, className, floatingImageData, floatingImageAlt, transformStyle} = props;
	const classes = useImgWithFloatingClippedImgStyles({
		maxWidth: primaryImageMaxWidth,
		transformStyle: transformStyle || "translate(50%, 40%) rotate(20deg)",
	});
	return (
		<div className="text-center">
			<div className={classes.imgContainer}>
				<GatsbyImage alt={primaryImageAlt} image={primaryImageData} className={className}/>
				<div className={classes.floatingImgWrapper}>
					<div className={classes.floatingImgSpacePermitted}>
						<div className={classes.floatingImg}>
							<GatsbyImage alt={floatingImageAlt} image={floatingImageData}/>
						</div>
					</div>
					{/*Take up space so the floating image shrinks at a certain point when the screen does*/}
					<div className={classes.spaceInhibitor}/>
				</div>
			</div>
		</div>
	);
}
ImgWithFloatingClippedImg.propTypes = {
	primaryImageData: PropTypes.object.isRequired,
	primaryImageAlt: PropTypes.string.isRequired,
	/** The primary image's max width */
	primaryImageMaxWidth: PropTypes.number.isRequired,
	/** The class name for the primary image */
	className: PropTypes.string,
	floatingImageData: PropTypes.object.isRequired,
	floatingImageAlt: PropTypes.string.isRequired,
	/** The optional transform style, default is "translate(50%, 40%) rotate(20deg)" */
	transformStyle: PropTypes.string,
};
